import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';

import { Section, SectionHeader, Container } from '@components/global';
import Button from '@common/Button';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  onSubmit = state => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': 'contact', ...state }),
    })
      .then(() =>
        this.setState({
          submitted: true,
        })
      )
      .catch(error =>
        this.setState({
          submitted: false,
        })
      );
  };

  resetState = () => this.setState({ submitted: false });

  render() {
    if (this.state.submitted) {
      return (
        <Fade>
          <Success>
            Tack för ditt meddelande, jag återkommer så fort jag kan
            <Buttons>
              <Button onClick={this.resetState} primary>
                Nytt meddelande
              </Button>
            </Buttons>
          </Success>
        </Fade>
      );
    } else {
      return (
        <StaticQuery
          query={graphql`
            query {
              johan: file(
                sourceInstanceName: { eq: "team" }
                name: { eq: "johan" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 760) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          `}
          render={data => (
            <StyledSection>
              <Container>
                <Fade>
                  <SectionHeader id="contact">Kontakt</SectionHeader>
                  <Grid>
                    <InnerGrid>
                      <Image
                        sizes={data.johan.childImageSharp.fluid}
                        alt="Johan Svanström"
                      />
                      <p>
                      Ring eller fyll i formuläret intill för kontakt så kan jag delge referenser och hantera ert kommande projekt. 
                      </p>
                    </InnerGrid>
                    <ContactForm onSubmit={this.onSubmit} />
                  </Grid>
                </Fade>
              </Container>
            </StyledSection>
          )}
        />
      );
    }
  }
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: '', email: '', message: '' };
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state);
  };

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value });

  render() {
    const { name, email, message } = this.state;
    return (
      <form
        onSubmit={this.handleSubmit}
        method="POST"
        name="contact"
        data-netlify="true"
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p className="hidden">
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <label>
          Namn{' '}
          <input
            type="text"
            name="name"
            value={name}
            onChange={this.handleChange}
            required
          />
        </label>
        <label>
          Email{' '}
          <input
            type="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            required
          />
        </label>
        <label>
          Meddelande{' '}
          <textarea
            name="message"
            value={message}
            onChange={this.handleChange}
            required
          />
        </label>
        <Buttons>
          <Button type="submit" primary>
            Skicka
          </Button>
        </Buttons>
      </form>
    );
  }
}

const StyledSection = styled(Section)`
  transition: 0.4s;
`;

const Success = styled.div`
  text-align: center;
  margin-top: 100px;
`;

const Grid = styled.div`
  text-align: left;
  display: block;
  margin: 50px 0;

  &:nth-child(1)  {
    align-items: flex-start;
    p {
      ${props => props.theme.font_size.inBetween};
    }
  }

  &:nth-child(2)  {
    align-items: center;
  }

  form {
    width: 100%;
    label {
      font-size: ${props => props.theme.font_size.small};
      input,
      textarea {
        display: block;
        border: 1px solid ${props => props.theme.color.text.primary};
        padding: 15px;
        resize: none;
        width: 100%;
        margin: 10px 0 20px 0;
      }
      textarea {
        height: 188px;
      }
    }
    .hidden {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    justify-items: center;
    margin: 180px 0 50px 0;
  }
`;

const InnerGrid = styled.div`
  display: grid;
  align-content: end;
  grid-template-columns: 1fr;
  grid-auto-rows: 330px auto;

  .gatsby-image-wrapper {
    width: 220px;
  }
`;

const Buttons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

export default Contact;
