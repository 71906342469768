import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Section, SectionHeader, Container } from '@components/global';
import Button from '@common/Button';
import ProjectList from '@common/ProjectList';

import Fade from 'react-reveal/Fade';

const Projects = ({ data }) => (
  <Section>
    <Container>
      <Fade>
        <SectionHeader id="projects">Projekt</SectionHeader>
      </Fade>
      <ProjectList projects={data} />
      <Fade>
        <Buttons>
          <Link to="/projekt">
            <Button primary>Se alla projekt</Button>
          </Link>
        </Buttons>
      </Fade>
    </Container>
  </Section>
);

const Buttons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;

  /* marginalerna ska inte påverka små skärmar, flyttar till screen.lg sålänge */
  @media (min-width: ${props => props.theme.screen.lg}) {
    margin: 100px 0 210px 0;
  }
`;

export default Projects;
