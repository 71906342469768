import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import Services from '@sections/Services';
import Projects from '@sections/Projects';
import About from '@sections/About';
import Contact from '@sections/Contact';
import Footer from '@sections/Footer';

const IndexPage = ({ data }) => (
  <Layout>
    <Navbar index={true} />
    <Header />
    <Services />
    <Projects data={data.allMarkdownRemark.nodes} />
    <About />
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        frontmatter {
          date
          images {
            childImageSharp {
              fluid(maxWidth: 3840) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
          title
          type
        }
        fields {
          slug
        }
        excerpt(format: PLAIN, pruneLength: 140, truncate: false)
      }
    }
  }
`;
