import React from 'react';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';

import { Section, SectionHeader, Container } from '@components/global';
import Button from '@common/Button';

import sbrLogo from '@images/logos/sbr_logo.svg';
import Pdf from './../../files/CV.pdf';

const About = () => (
  <Section>
    <Container>
      <Fade>
        <SectionHeader id="about">Om oss</SectionHeader>
        <Paragraph first>
          Johan Svanström har mer än 35 års erfarenhet av byggsektorn. Verksam
          inom byggproduktion i 18 år och bygg- och projektledare i 17år.
          Verksamhetsområden har varit nyproduktion av bostäder, anläggningar
          och (rot) renovering - ombyggnad och tillbyggnad. Vanligast
          förekommande kunder är bostadsrättsföreningar, fastighetsägare och
          byggbolag.
          <br />
          <br />
          Vi hjälper kunden att få kontroll från början i projektet och finns
          oftast med i hela processen fram till slutbesiktning och avslutande
          möte mellan parter. Vi har ett brett nätverk av tekniska konsulter,
          sakkunniga inom de områden som fordras.
        </Paragraph>
        <Paragraph>
          <img
            style={{ display: 'block' }}
            src={sbrLogo}
            alt="Svenska byggingenjörers riksförbund"
          />
          Medlem i SBR (Svenska Byggingenjörers Riksförbund.)
        </Paragraph>{' '}
        <Download href={Pdf} target="_blank">
          <Button primary>CV</Button>
        </Download>
      </Fade>
    </Container>
  </Section>
);

const Download = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.text.primary};
  justify-content: center;
  display: flex;

  &:hover > button {
    text-decoration: underline;
  }
`;

const Paragraph = styled.p`
  ${props => props.theme.font_size.base};
  line-height: 2; /* Overriding the line-height for the subtitle */

  ${props =>
    props.first &&
    css`
      @media (min-width: ${props => props.theme.screen.lg}) {
        margin: 180px 0 50px 0;
      }
    `}

  @media (min-width: ${props => props.theme.screen.lg}) {
    ${props => props.theme.font_size.inBetween};
    padding: 0 160px;
  }
`;

export default About;
