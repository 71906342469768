import React from 'react';
import styled from 'styled-components';

import { Section, SectionHeader, Container } from '@components/global';
import Button from '@common/Button';

import besiktningarIcon from '@images/icons/ic-besiktningar.svg';
import vindsprojektIcon from '@images/icons/ic-vindsprojekt.svg';
import projektledningIcon from '@images/icons/ic-projektledning.svg';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import Fade from 'react-reveal/Fade';

const Services = () => (
  <Section>
    <Fade>
      <Container>
        <SectionHeader id="services">Tjänster</SectionHeader>
      </Container>
    </Fade>
    <Container>
      <Fade>
        <Grid>
          <Item>
            <img src={besiktningarIcon} alt="Besiktningar icon" />
            <h3>Besiktningar</h3>
            <ul>
              <li>
                Konditionsbesiktningar av t ex fasader, tak, fönster och
                balkonger.
              </li>
              <li>Upprättande av underhållsplaner.</li>
              <li>Skadeutredningar med rapportskrivning.</li>
            </ul>
          </Item>
          <Item>
            <img src={vindsprojektIcon} alt="Vindsprojekt icon" />
            <h3>Vindsprojekt</h3>
            <ul>
              <li>
                Bostadsrättsföreningens sakkunnige kontrollant och projektledare
                vid vindsombyggnad.
              </li>
            </ul>
          </Item>
          <Item>
            <img src={projektledningIcon} alt="Projektledning icon" />
            <h3>Projekt- och byggledning</h3>
            <ul>
              <li>Stambyte med tillhörande våtrumsombyggnader.</li>
              <li>Fasadrenoveringar</li>
              <li>Balkong och terrassrenoveringar.</li>
              <li>Fönsterrenoveringar</li>
              <li>
                Upprättande av förfrågningsunderlag för allt inom ROT,
                anbudsinfordran och upphandling av entreprenör med
                kontraktsskrivning.
              </li>
            </ul>
          </Item>
        </Grid>
      </Fade>
      <Fade>
        <Buttons>
          <AnchorLink href={`#contact`}>
            <Button primary>Kontakt</Button>
          </AnchorLink>
        </Buttons>
      </Fade>
    </Container>
  </Section>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  text-align: left;
  justify-items: center;
  margin: 24px 0;
  grid-template-areas:
    'first-area'
    'second-area'
    'bottom-area';

  /* flyttade min-height och margin-top till en större skärm, kanske bör vara ännu större? */
  @media (min-width: ${props => props.theme.screen.md}) {
    min-height: 600px;
    margin-top: 180px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto auto;
    grid-template-areas:
      'first-area second-area'
      'bottom-area bottom-area';
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'first-area second-area bottom-area';
  }
`;

const Item = styled.div`
  img {
    justify-self: center;
    display: flex;
    margin: 50px auto;
    height: 116px;
    width: 116px;
  }
  &:nth-child(1) {
    grid-area: first-area;
  }

  &:nth-child(2) {
    grid-area: second-area;
  }

  &:last-child {
    grid-area: bottom-area;

    @media (min-width: ${props => props.theme.screen.md}) {
      margin: 0 160px;
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      margin: 0;
    }
  }
`;

const Buttons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;

  /* marginalerna ska inte påverka små skärmar, flyttar till screen.lg sålänge */
  @media (min-width: ${props => props.theme.screen.lg}) {
    margin: 40px 0 210px 0;
  }
`;

export default Services;
