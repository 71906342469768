import React from 'react';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import Button from '@common/Button';
import { Section, Container } from '@components/global';
import AnchorLink from 'react-anchor-link-smooth-scroll';


const Header = () => (
  <Wrapper id="header">
    <Grid>
      <InnerGrid text>
        <Container>
          <Text>
            <h1>Vi hjälper er få kontroll över byggprojektet</h1>
            <Paragraph>
              Johan Svanström har mer än <strong>35 års erfarenhet</strong> av
              byggsektorn. Verksam inom byggproduktion i 18 år och som bygg- och
              projektledare i 17 år. Versamhetsområden har varit{' '}
              <strong>nyproduktion av bostäder</strong>, anläggningar och{' '}
              <strong>(rot) renovering</strong> - ombyggnad och tillbyggnad.
            </Paragraph>
            <Buttons>
              <AnchorLink href={`#contact`}>
                <Button primary>Kontakt</Button>
              </AnchorLink>
            </Buttons>
          </Text>
        </Container>
      </InnerGrid>
      <InnerGrid artInner>
        <Art>
          <StaticQuery
            query={graphql`
              query {
                header_art: file(
                  sourceInstanceName: { eq: "art" }
                  name: { eq: "header" }
                ) {
                  childImageSharp {
                    fluid(maxWidth: 760) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            `}
            render={data => (
              <Image alt="art" fluid={data.header_art.childImageSharp.fluid} />
            )}
          />
        </Art>
      </InnerGrid>
    </Grid>
  </Wrapper>
);

const Wrapper = styled(Section)`
  background: ${props => props.theme.color.background.primary};
  padding-top: 55px;
  min-height: calc(100vh - 55px);
`;

const Art = styled.figure`
  height: 100%;
  width: 100%;
  margin: 0;
  grid-area: image-area;

  /* bestäm höjd på bild */
  .gatsby-image-wrapper {
    max-height: 300px;

    /* provar detta istället, sektionen var lite större än bilden, blev inget bra */
    @media (min-width: ${props => props.theme.screen.md}) {
      min-height: 100vh;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'image-area'
    'text-area';

  @media (min-width: ${props => props.theme.screen.xs}) {
    grid-template-areas:
      'image-area'
      'text-area';
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr 56%;
    grid-template-areas: 'text-area image-area';
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    display: grid;
    grid-template-columns: 1fr 43.65853659%;
    max-width: 100%;
    grid-template-areas: 'text-area image-area';
  }
`;

const InnerGrid = styled.div`
  display: grid;
  align-items: center;
  grid-area: text-area;
  margin: 0;

  ${props =>
    props.text &&
    css`
      @media (min-width: ${props => props.theme.screen.md}) {
        justify-self: right;
        max-width: 442px;
        padding: 0 20px;
      }

      @media (min-width: ${props => props.theme.screen.lg}) {
        max-width: 765px;
      }

      @media (min-width: ${props => props.theme.screen.xl}) {
        max-width: 810px;
      }
    `}

  ${props =>
    props.artInner &&
    css`
      max-height: 298px;
      display: block;
      margin: 0 !important;
      grid-area: image-area;
      max-width: none;
      justify-self: none;

      @media (min-width: ${props => props.theme.screen.sm}) {
        max-height: 298px;
      }

      @media (min-width: ${props => props.theme.screen.md}) {
        max-height: 100vh;
      }
    `}
`;

const Text = styled.div`
  justify-self: start;
  width: 100%;

  h1 {
    font-size: 38px;

    @media (min-width: ${props => props.theme.screen.lg}) {
      ${props => props.theme.font_size.xlarge};
    }
  }
`;

const Paragraph = styled.p`
  ${props => props.theme.font_size.base};
  line-height: 2; /* Overriding the line-height for the subtitle */

  @media (min-width: ${props => props.theme.screen.lg}) {
    ${props => props.theme.font_size.inBetween};
  }
`;

const Buttons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;

  @media (min-width: ${props => props.theme.screen.md}) {
    justify-content: flex-start;
  }
`;

export default Header;
